body {
  background-color: #f7f9fc;
}

.blog-container h1 {
  text-align: start;
  margin: 0;
  margin-top: 60px;
  margin-bottom: 20px;
}

.main-blog-post {
  position: relative;
  margin-bottom: 20px;
  width: 70%;
  margin: 0 auto;
}

.main-blog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff; /* Card background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial box shadow */
  padding: 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s; /* Smooth transition for transform and shadow */
  border: 1px solid #e0e0e0; /* Light gray border */
  margin-bottom: 100px;
}

.main-blog-card:hover {
  transform: scale(1.05); /* Slightly scale up the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a 'lifted' effect */
}

.main-blog-overlay {
  position: absolute;
  bottom: -30px;
  left: 30px;
  background: rgba(255, 255, 255); /* Semi-transparent white background */
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgb(215, 215, 215);
  width: 50%; /* Fixed width for the overlay */
}

.main-blog-title {
  color: #333f58; /* Primary title color */
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: start;
}

.main-blog-image {
  width: 100%;
  height: 350px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.3s; /* Smooth transition for zoom effect */
}

.main-blog-categories {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 10px 0;
  padding: 0;
}

.main-blog-categories li {
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.blog-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.blog-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff; /* Card background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial box shadow */
  padding: 15px;
  transition:
    transform 0.3s,
    box-shadow 0.3s; /* Smooth transition for transform and shadow */
  border: 1px solid #e0e0e0; /* Light gray border */
}

.blog-card:hover {
  transform: scale(1.05); /* Slightly scale up the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a 'lifted' effect */
}

.blog-title {
  color: #333f58; /* Primary title color */
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: start;
}

.blog-image {
  width: 100%;
  height: auto; /* Adjust the height as needed */
  border-radius: 8px;
  margin-bottom: 10px;
}

.blog-categories {
  list-style: none;
  display: flex;
  gap: 8px;
  margin: 10px 0;
  padding: 0;
}

.blog-categories li {
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.author-and-published-date {
  display: flex;
  justify-content: space-between;
  margin-top: auto; /* Pushes this section to the bottom */
}

.blog-author {
  color: #42c4df; /* Adjust color as needed */
  font-size: 0.9em;
}

.blog-published-date {
  color: #788996;
  font-size: 0.85em;
}

.blog-author-by {
  color: #788996;
}

#mc_embed_signup .indicates-required {
  display: none;
}

#mc_embed_signup .asterisk {
  display: none;
}

@media (max-width: 600px) {
  .main-blog-title {
    font-size: 14px;
  }

  .main-blog-overlay {
    width: 90%;
    left: 0;
    opacity: 0.9;
    bottom: 0;
  }

  .main-blog-post {
    width: 80%;
  }
}
