.blog-post-container {
  max-width: 85%;
  margin: 0 auto;
  padding: 20px;
}

.blog-post-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border-radius: 25px;
  margin-bottom: 20px;
  margin: 0 auto;
  display: block;
}

.blog-post-title {
  font-size: 35px;
}

.recent-posts {
  margin-top: 40px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.recent-post-link {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  color: inherit;
}

.recent-post-link img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* Recent Posts Container */
.recent-posts-container {
  margin-top: 70px; /* Space above the recent posts section */
  /* Padding around the container */
}

/* Grid layout for recent posts */
.recent-posts-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Adjust grid column size */
  gap: 15px; /* Space between cards */
  justify-content: center;
}

/* Styling for individual cards in the recent posts */
.recent-post-card {
  background-color: #ffffff; /* White background for each card */
  border: 1px solid #ddd; /* Light grey border */
  border-radius: 10px; /* Rounded corners for the cards */
  overflow: hidden; /* Ensures no content spills outside the card */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Image styling for recent posts */
.recent-post-image {
  width: 100%;
  height: 120px; /* Fixed height for images */
  object-fit: cover; /* Ensures images cover the area without distortion */
}

/* Title and metadata container */
.recent-post-content {
  padding: 10px; /* Padding inside the card */
}

/* Title styling for recent posts */
.recent-post-title {
  font-size: 14px; /* Smaller font size for titles */
  font-weight: bold;
  margin-bottom: 5px; /* Space below the title */
  color: #333; /* Dark grey text color */
}

/* Author and date styling */
.recent-post-author,
.recent-post-date {
  font-size: 12px; /* Smaller font size for metadata */
  color: #666; /* Lighter text color for less emphasis */
}

.blog-author-date {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: black;
  font-weight: 500;
}

.author,
.date {
  background-color: #dddddd;
  border-radius: 20px;
  padding: 10px 20px;
}

.blog-body-image-container {
  margin: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px !important;
  margin-top: 35px !important;
}

.blog-content {
  margin-bottom: 100px !important;
}

.blog-content p {
  margin: 0 !important  ;
  margin-top: 10px !important;
}

.blog-content h2 {
  margin: 0 !important  ;
  margin-top: 20px !important ;
}

.blog-content h2:nth-of-type(1) {
  margin-top: 0px !important;
}

.blog-body-image-container img {
  height: 350px;
  width: 380px;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 600px) {
  .blog-post-image {
    height: 175px;
  }

  .blog-post-container {
    max-width: 82%;
  }

  .blog-post-title {
    font-size: 24px;
    text-align: center;
  }

  .blog-author-date {
    margin-bottom: 0px;
    margin-top: 5px;
    font-size: 13px;
  }

  .blog-body-image-container img {
    height: 250px;
    width: 100vw;
    object-position: 70% 85%;
  }

  .blog-content h2 {
    font-size: 19px;
    margin-top: 8px !important;
  }

  .blog-content h2:nth-of-type(1) {
    margin-top: 10px !important;
  }

  .blog-content p {
    font-size: 15.5px;
    margin-bottom: 8px !important;
  }

  .blog-body-image-container {
    gap: 20px;
  }
}
