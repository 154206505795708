.footer-content {
    background-color: #e9ecef;
    color: #333;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top:20px;
    width:60%;
    margin:0 auto;
 
  }

  .footer-content{
    border-bottom:1px solid #cecece;
   padding-bottom:10px;
   
  }
  
  .footer-section h2, .footer-section h3 {
    color: #333;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    padding-bottom: 5px;

  }

  .footer-section li a{
    color:black;
    text-decoration: none;
    font-size:16px;
  }
  
  .footer-section p, .footer-section li {
    font-size: 17px;
  }
  
 .FooterLogo{
    margin-left:-10px;
 }

 .footer-container{
    background-color:#e9ecef;
 
 }

 footer .footer-section:nth-of-type(2){
    align-items: start ;
 }

 .copyright-social{
    display:flex;
    flex-direction: column;
    text-align: center;
    margin-top:30px;
 }
 .footer-social{
    display:flex;
    justify-content: center;
    gap:10px;
 }

.footer-image{
  position: absolute;
  padding:20px;
  padding-left:50px;
}

 
 @media (max-width: 600px) {
  .footer-content {
      flex-direction: row;
      align-items:start;
      text-align: start;
      padding: 0;
      margin:0;
      width:90%;
      margin:0 auto;
  }

  .footer-section {
      margin-bottom: 20px;
  }

  .footer-section h2,
  .footer-section h3 {
      margin-top: 20px;
      font-size:15px;
      padding-right: 10px;
  }

  .FooterLogo {
      margin-left: 0;
  }
  .footer-section img {
      height:90px;
      
  }

  .footer-section p{
    font-size:15px;
  }

  .footer-section img {
    text-align: center;
    display: block;
    margin: 0 auto;
    padding-top:20px;
}

  .footer-section {
      margin-bottom:0;
      width:min-content;
      width:95%;
  }

  .footer-image{
    position:initial;
    padding-left:0px;

  }
}