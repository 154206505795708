@font-face {
    font-family: 'Gilroy-Medium';
    src: local('Gilroy-Bold'), url('/src/fonts/Gilroy-Bold.ttf') format('truetype');
  }


.download-now-section{
    display:flex;
    justify-content: center;
    width:70%;
    margin:0 auto;
    font-family: 'Gilroy-Medium';
    
   
}

.download-now-container{
    background: linear-gradient(to top, #8ECBDB 14%, #F6F9FC 100%);
  
}

.download-now-intro{
    align-self: center;
    padding-bottom:100px;

}

.download-now-intro h3{
    width:70%;
   
}
.download-now-intro p{
    font-size:18px;
    margin-bottom:20px;
}

.download-now-phone-screens img{
    height:530px;
}

.download-now-intro h1{
    color:black;
    font-family: 'Gilroy-Medium';
    font-size:44px;
}

@media (max-width: 600px) {
    .download-now-phone-screens img{
        height:350px;
        display:block;
        margin:0 auto;
        width:auto;
    }

    .download-now-intro h1{
        font-size:30px;
    }

    .download-now-section{
        flex-direction: column;
    }

    .download-now-intro h3 {
        width: 100%;
        font-size:16px;
    }
    .download-now-intro p {
        width: 100%;
        font-size:16px;
    }
  
    .download-now-intro {
        padding-bottom: 50px;
    }  
    
    
}