.announcement-banner {
    background-color: #5bad72; 
    color: white;
    text-align: center;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center; 
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.5s ease-in-out, pulse 1.5s infinite;
    position:relative;
    z-index: 1100;
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  

  