@font-face {
    font-family: 'Gilroy-Bold';
    src: local('Gilroy-Bold'), url('/src/fonts/Gilroy-Bold.ttf') format('truetype');
  }



.phone-wrapper {
    width: 500px; 
  height: 500px; 
  background: linear-gradient(180deg, rgba(161, 220, 178, 0.635) 0%, rgba(255,255,255,0) 65%);
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
.home-page{
    background-color: #F6F9FC;
}


header{
    display:flex;
    width: 80%;
    justify-content: space-between;
    margin: 0 auto;
    padding-top:80px;
    gap:30px;
    flex-direction:row;
   
}
  .home-header h1 {
    color: #232323;
    font-size: 54px;
    margin:0;
    padding:0;
    width:85%


  }
  
  .home-header p {
    color: #666;
    font-size: 1.2em;
    margin-top:10px;
    margin-bottom:50px;
    width:95%

  }

  header.home-header p:first-of-type {
    color: #5bad72;
    font-weight: 700;
    font-size:34px;
    font-style: italic;
  }
  
  .home-content p {
    margin: 20px 0;
    color: #444;
  }
  
  .explore-btn {
    padding: 10px 20px;
    background-color: #5bad72;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .explore-btn:hover {
    background-color: #4a8e5a;
  }
  
  .home-header span {
    background-image: url('/public/Images/green-slash.png'); 
    background-repeat: no-repeat;
    background-size: contain; 
    background-position: center bottom; 
    padding-bottom: 0px; 
    display: inline; 
    font-size: 40px;
    width: 100%; 
}

.download-app-store-links img{
    height:60px;
    width:auto;
}

.download-app-store-links{
    display:flex;
    gap:20px;
}


.recent-blogs h2 {
    text-align: center;
    font-size: 34px;
    margin: 20px 0;
}

.recent-blogs{
    margin-bottom:30px;
    margin-top:50px
}


.blog-list-container2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  
    gap: 20px; 
    justify-content: center;
    width:70%;
    margin:0 auto;
   
 
  
  }
  
  .blog-card2 {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    background-color: #ffffff; /* Card background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial box shadow */
    padding: 15px;
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for transform and shadow */
    
  }

.blog-card2:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}




/* Specific styling to ensure consistency and separation */
.blog-author-by {
    color: #788996;
}

/* Navigation arrows style */
.slick-arrow {

    color: white !important; /* White arrow color */
    font-size: 34px !important; /* Larger arrow */
    width: 40px !important; /* Wider arrow button */
    height: 40px !important; /* Taller arrow button */
    border-radius: 50%; /* Circular button */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.slick-slider{
    width:1070px;
    margin:0 auto;


}

.slick-prev:before, .slick-next:before{
    color:#5bad72 !important;
    font-size:40px !important;
    opacity:1 !important;
}

.slick-prev {
    left: -50px !important;
}

.slick-next {
    right: -15px !important;
}

.blog-list-container2 {
    width: 90%;
    max-width: none;
}

.blog-card2{
    max-width: 270px; /* Max width of each card */
    margin: 0 10px; /* Spacing around each card */
    height:340px;
    margin-bottom:50px
}

a:-webkit-any-link {
    text-decoration: none; 
}

.author-and-date2{
    display:flex;
    justify-content: space-between;
    margin-top: auto
}

.blog-content{
display:flex;
flex-direction: column;
justify-content: space-between;
height:auto;
}

.why-choose-us-p {

    font-size:21px;
    margin-bottom:60px;
    text-align: center;
}

.why-choose-us h2{
    text-align: center;
    font-size:35px;
    width:70%;
    margin:0 auto;
    margin-top:50px;

}

.why-choose-us p:nth-of-type(2) {
    text-align: center;
}

.why-choose-us-cards{
    display:flex;
    height:100px;
    width:450px;
    color:black;
    background-color:white;
    align-items: center;
    gap:20px;
    padding-left:20px;
    border-radius:10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.05); 
    transition: all 0.3s ease
}

.why-choose-us-cards:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), 0 -6px 12px rgba(0, 0, 0, 0.1); 
    transform: translateY(-2px); 
}

.why-choose-us-card-container{
    display:flex;
    flex-wrap: wrap;
    gap:30px;
    justify-content: center;
    width:90%;
    margin: 0 auto;
    margin-bottom:120px;
  
}

.why-choose-us-cards img{
    height:35px;
    width:auto;
    padding:6px 10px;
    padding-top:9px;
    color:white;
}

.why-choose-us-cards div{
    border-radius:10px;
}

.why-choose-us-cards-info h3{
    margin-top:0;
    margin-bottom: 0;

}

.why-choose-us-cards-info p{
    margin-top:0;
    margin-bottom: 0;
}

.why-choose-us-cards-info{
    display:flex;
    flex-direction: column;
    gap:5px;
    margin-bottom:auto;
    padding-top:7px;
}

.multiple-phone-screen-display img{
    width:100%;
    object-fit:cover;
    transform-origin: center center; transform: scale(1.1); 

}
.multiple-phone-screen-display{
    margin-bottom:120px;
}

html, body {
    overflow-x: hidden;
  
}

.adventure-container{
    display:flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    margin-bottom:80px;
}

.advetnture-info{
    width:30% !important
}

.advetnture-info h2{
    font-size: 30px;
}

.advetnture-info p{
    font-size: 18px;
}

.download-now-container {
    background: linear-gradient(to top, #8ECBDB 14%, #ffffff 100%);
}

/* Adjustments for the Recent Blogs section on mobile */
@media (max-width: 600px) {
    .recent-blogs {
        margin-bottom: 70px;
        margin-top: 50px;
    }

    .multiple-phone-screen-display img {
        display:none;
    }

    .home-header h1 {
        font-size:30px;
        width:95%
      }
    
      .home-header span{
        font-size:26px;
      }
    
      .home-header p:first-of-type {
        font-size:19px !important;
      }

      header.home-header p:first-of-type {
        margin-bottom:0px ;
      }
    
      .home-header p{
        font-size:15px;
        margin-bottom:20px;
      }
    
      .home-header{
        flex-direction:column;
        width:90%;
      justify-content: center;
      align-items: center;
      margin-top:50px;
      }
    
      .phone-wrapper img{
        height:250px;
        width:auto;
      }
      .phone-wrapper {
        height:290px;
        width:290px;
      }
    
      .download-app-store-links img{
        height:45px;
      }
    
      .home-header-container{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    
      .why-choose-us h2{
        font-size:30px;
        margin-top:30px;
      }
    
      .why-choose-us-p{
        font-size:17px;
        width:90%;
        margin:0 auto;
        margin-top:10px;
        margin-bottom: 30px;;
      }
    
      .why-choose-us-cards {
        height: 98px;
        width: 360px;
    }
    
    .why-choose-us-cards-info h3{
      font-size:16px;
    }
    
    .why-choose-us-cards-info p{
      font-size:13px;
    }
    
    .download-app-store-links {
        display: flex;
        gap: 10px;
    }

    .why-choose-us-card-container{
        margin-bottom:70px;
    }

    .multiple-phone-screen-display {
        margin-bottom:70px;
    }

.home-header{
    margin-top:0 !important;
    width:85% !important;
}

header{
    padding-top:50px !important;
}
    .recent-blogs h2 {
      font-size: 24px; /* Smaller font size for the heading */
      margin: 20px 10px; /* Reduced margin */
    }
  
    .slick-slider {
      width: 70%; /* Full width for easier management */
      padding: 0 10px; /* Padding to prevent touch at the edge */
   
    }
  
    .blog-card2 {
      max-width: 100%; /* Full width cards within the carousel */
      margin: 0 5px; /* Smaller lateral margins */
      height: auto; /* Height auto for dynamic adjustment */
      padding: 10px; /* Reduced padding */
    }
  
    .blog-card2 img {
      width: 100%; /* Ensure images resize correctly */
      height: auto; /* Maintain aspect ratio */
    }
  
    .blog-content {
      padding-top: 10px; /* Space between image and text content */
    }
  
    .author-and-date2 {
      font-size: 14px; /* Smaller font size for author and date */
    }
  
    .blog-title {
      font-size: 16px; /* Smaller title font size */
    }

    .blog-card2 {
        width:220px !important;
        height:350px;
    }
      
    
        .slick-prev, .slick-next {
          display: none; /* Hide arrows on very small devices if needed */
        }
    
        .slick-next {
            right: -35px !important;
        }
    
        .slick-prev {
            left: -31px !important;
        }
  }

  .download-app-store-links {
    display: flex;
    gap:10px !important;
}


  
  