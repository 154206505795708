@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url('/src/fonts/Gilroy-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url('/src/fonts/Gilroy-Medium.ttf') format('truetype');
}

body {
  font-family: 'Gilroy-Medium';
}

.nav-container {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  width: 100%;
  position:relative;
  top: 0;
  z-index: 1200;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 95%;
  margin: 0 auto;
  height: 90px;
}

.nav-logo {
  font-size: 1.5em;
  color: #333;
  text-decoration: none;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
}

.nav-links a {
  text-decoration: none;
  color: #666;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  font-size: 17px;
}

.nav-links a:hover {
  color: #333;
}

.nav-download-now {
  background-color: #42C2DF;
  padding: 14px 20px;
  border: none;
  font-size: 17px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.nav-download-now:hover {
  opacity: 0.9;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  z-index: 2000; /* Ensure it is above the nav links */
  flex-direction: column;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
}

.hamburger-menu.active div:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.hamburger-menu.active div:nth-child(2) {
  opacity: 0;
  transform: scale(0);
}

.hamburger-menu.active div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.coming-soon-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top:65px;
  background: rgba(51, 51, 51, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}

.coming-soon-text {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  animation: bounce 1s infinite;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

/* Mobile Responsive Styles */
@media (max-width: 600px) {
  .nav-content {
    flex-direction: row;
    justify-content: space-between;
    height:60px;
  }

  .announcement-banner p {
    font-size: 11px;
    width:94%;
  }

 
  .nav-logo img {
    height: 50px;
    padding-top: 5px;
}


  .nav-download-now {
    display: none;
  }

  .announcement-banner {
    height: 50px !important;
  }

  .hamburger-menu {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 100;
    gap: 0;
    padding-top: 50px;
  }

  .nav-links.active {
    transform: translateX(0);
  }

  .nav-links a {
    padding: 15px;
    display: block;
    width: 100%;
    text-align: left;
  }

  .close-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }



  
}
