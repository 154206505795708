@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('/src/fonts/Gilroy-Bold.ttf') format('truetype');
}

.about-header {
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(255, 255, 255, 1)), url('/public/Images/Group33.png') no-repeat center center/cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
  font-family: 'Arial', sans-serif;
  position: relative;
  opacity: 0.8;
}


.about-header, .our-mission, .team, .history {
  margin-bottom: 20px;
}

h1, h2 {
  color: #000000;
}

body {
  background-color: #ffffff;
}

.about-header h1 {
  font-size: 40px;
  font-family: 'Gilroy-SemiBold';
  text-align: center;
}

.benefits-section {
  text-align: center;
  padding-top: 10px;
}

.benefits-subtitle {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 40px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.benefits-cards {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.benefit-card {
  width: 20rem;
  height: 12rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #e4f1e8 10%, rgba(255, 255, 255, 1) 100%);
  border-radius: 10rem 10rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top:40px;
  margin-bottom: 40px; /* Add some space between cards */
}


.benefit-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  z-index: 1;
}

.benefit-card h3 {
  font-size: 1.5em;
  color: #333;
  z-index: 1;
  margin: 0;;
}

.benefit-card p {
  font-size: 1em;
  color: #666;
  z-index: 1;
}

.about-header h1 {
  position: relative;
  z-index: 1;
  
}

.about-header::before {
  content: '';
  position: absolute;
  top: 90%;
  left: 0;
  width: 100%;
  height: 10%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(255, 255, 255));
  z-index: 0;
}

.span-benefits {
  color: #5bad72;
}

.benefits-section h2 {
  font-size: 28px;
  font-family: 'Gilroy-SemiBold';
}

.benefit-card img{
  height:70px;
  width:100px;
}

.cherished-memories-section{
  display:flex;
  justify-content: space-between;
  width:74%;
  margin:0 auto;
  margin-top:50px;
  margin-bottom: 100px;
}

.cherished-memories{
  width:40%;
  align-self: center;
}

.cherished-memories-img img{
  height:340px;
  margin-top:20px;
}

.cherished-memories h2{
  font-size: 34px;
  font-family: 'Gilroy-SemiBold';
}

.cherished-memories p{
  font-size: 19px;
  font-family: 'Gilroy-Medium';
}

@media (max-width: 600px) {
  .benefits-cards{
    flex-direction: column;
  }

  .benefit-card{
    width:75vw;
    margin:0 auto;
    padding-top:20px
  }

  .benefit-card img {
    height: 50px;
    width: auto;
}

.benefit-card h3 {
  font-size: 20px;
}

.benefit-card p {
  font-size: 15px;
  width:90%;
}

.cherished-memories-section{
  flex-direction: column;
}

.cherished-memories {
  width:100%
}

.cherished-memories-img img {
  width:90vw;
  height:auto;
  margin:0 auto
}

.cherished-memories-section{
  width:90%;
  margin-bottom:40px;
}

.cherished-memories h2 {
  font-size:28px;
}

.cherished-memories p {
  font-size:16px;
  margin-bottom:50px;
}

.benefits-subtitle {
  font-size: 16px;

}
}