@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url('/src/fonts/Gilroy-Bold.ttf') format('truetype');
}

.contact-image-container {
  background-image: url('/public/Images/Group1.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #C3EAD3;
  padding-bottom:30px;
}

.contact-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
}

.contact-container h1 {
  text-align: center;
}

.contact-info {
  width: 40%;
}

.contact-method {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
}



.contact-form h2 {
  margin-top: 0;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: none; /* Remove the border */
  background: transparent;
  background-color: rgba(255, 255, 255);
  border-radius: 4px;
  color: #8D8D8D;
}

.contact-form button {
  padding: 10px;
  border-radius: 4px;
  background-color: #5bad72;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #45a049;
}

.form-status {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  font-size: 1em;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

.success {
  background-color: #42c2df;
}

.error {
  background-color: rgb(240, 62, 47);
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  opacity: 0;
  visibility: hidden;
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
  font-size: 15px;
  font-family: 'Gilroy-Medium';
}

.send-us-a-message-section {
  background-color: #ececec;
  border-radius:8px;
  padding: 30px;
  width:400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 auto;
  border:#d5d5d5 1px solid
}



.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: none; /* Remove the border */
  background: transparent;
  background-color: rgba(255, 255, 255);
  border-radius: 4px;
  font-family: 'Gilroy-Light';
  color:black;
}

.email-us-section img {
  height: 200px;
  display: block;
  margin: 0 auto;
}


.email-us-icon-section img {
  height: 21px;
  margin: 0;
}

.email-us-icon-section p {
  font-size: 18px;
  margin: 0;
}

.email-us-icon-section {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 40px;
}


.contact-container p{
  text-align: center;
  margin:0 auto;
  color:#434444;
  margin-bottom:30px;
  width:50%;
  font-size:18px;
  font-family:'Gilroy-Bold';;
}

@media (max-width: 600px) {
  .contact-container{
    width:100%;
    margin:0 auto
  }

  .send-us-a-message-section{
    width:80%;
    margin:0 auto;
    padding:20px;
  }

  .contact-container p {
    font-size:16px;
    width:90%
  }

  .contact-form h2 {
    font-size: 22px;
  }
}