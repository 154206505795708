/* Add this to your existing CSS */
* {
  font-family: 'Gilroy-Medium';
}

.pricing-container {
  background: linear-gradient(to top, #ace2ee 14%, #FFFFFF 100%);
  width: 100%;
  padding-top:40px;
  padding-bottom:40px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.pricing-title {
  color: #333;
  font-size: 32px;
  margin-bottom: 10px;
}

.pricing-description {
  font-size: 18px;
  color: #666;
  
  width:60%;
  margin:0 auto;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  background-color: #eceff1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
}

.tab:hover {
  background-color: #d1d8de;
}

.plans {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom:30px;
}

.plan {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  border-radius: 15px;
  width: 400px;
}

.plan ul div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}


.premium {
  border-top: #27ae60 10px solid;
}

.basic {
  border-top: #7D6148 10px solid;
}

.plan h2 {
  color: #2c3e50;
  font-size: 30px;
}

.price {
  color: #27ae60;
  font-size: 24px;
  margin: 10px 0;
}

.plan ul {
  list-style: none;
  padding: 0;
}

.plan ul li {
  text-align: left;
  padding: 8px 0;
  color: #666;
}

.plan-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basic-price p {
  color: #7D6148;
}

.plan-price p {
  font-family: 'Gilroy-SemiBold';
}

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top:30px;
}

.toggle-label {
  margin: 0 10px;
  font-weight: bold;
  color: #666;
  font-size: 14px;
}

.toggle {
  display: flex;
  align-items: center;
  background-color: #d1d8de;
  border-radius: 15px;
  position: relative;
  width: 60px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle.yearly {
  background-color: #aee0bb;
}

.toggle-button {
  position: absolute;
  background-color: #888;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  top: 5px;
}

.toggle-button.monthly {
  transform: translateX(5px);
}

.toggle-button.yearly {
  transform: translateX(35px);
  background-color: #27ae60;
}

@media (max-width: 600px) {
.plans{
  flex-direction: column-reverse;
}

.plan{
  width:75vw;
  margin:0 auto;

}

.pricing-description {
font-size:16px;
}

.pricing-title{
  margin-top:10px;
}

*{
  font-family: Arial, Helvetica, sans-serif;
}
}