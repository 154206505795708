.how-it-works-page {
    font-family: Arial, sans-serif;
}

.how-it-works-header {
    display:flex;
    justify-content: center;
    align-items: center;
    width:75%;
    margin: 0 auto;
    margin-top:80px;

}

.how-it-works-header h1{
    font-size:45px;
    margin-bottom: 0px;
    
}
.how-it-works-header h2{
    font-size:30px;
    color:#5ca786;
    margin-top: 5px;
    width:70%
    
}

.how-it-works-header p{
    width:80%;
    margin-bottom: 20px;
    
}

.step{
    display:flex;
    justify-content: center;
    align-items: center;
    width:80%;
    margin:0 auto;
}

.step-reverse{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width:80%;
    margin:0 auto;
}